import BasePlugin from '../BasePlugin'

export default class CheckAdjustmentRequestRuleCommand extends BasePlugin {
  async execute () {
    let me = this
    let adjustmentRequestData = {
      'adjustmentRequestId': this.context.getCard().data.id,
      'actionTypeId': 2
    }
    this.context.getCard().setLoading(true)
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/CheckAdjustmentRequestRuleCommand`,
      adjustmentRequestData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      this.context.getCard().setLoading(false)
      if (response.data.is_require_additional_confirmation_from_user) {
        this.context.$msgbox({
          type: 'info',
          confirmButtonText: 'Да',
          showCancelButton: true,
          center: true,
          message: response.data.message,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetAdjustmentRequestRuleCommand`,
                adjustmentRequestData,
                {
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }
              ).then((response) => {
                if (response.data.is_base_scenario) {
                  this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetInvestmentRequestBaseScenarioAsyncCommand`,
                    {
                      'scenarioId': response.data.scenario_id,
                      'async': 'true'
                    },
                    {
                      headers: {
                        'Content-Type': 'application/json'
                      }
                    })
                }
              })
              done()
            } else {
              done()
            }
          }
        })
      } else {
        this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetAdjustmentRequestRuleCommand`,
          adjustmentRequestData,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        ).then((response) => {
          if (response.data.message) {
            me.context.$msgbox({
              type: 'info',
              message: response.data.message
            })
          }
          if (response.data.is_base_scenario) {
            this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetInvestmentRequestBaseScenarioAsyncCommand`,
              {
                'scenarioId': response.data.scenario_id,
                'async': 'true'
              },
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              })
          }
        })
      }
    })
  }
}
